<template>
  <div class="activity-container">
    <div class="activity-content">
      <div class="tab-secondNav" v-if="secondNav.length>0">
        <ul class="tab-secondNav-choose-item">
          <li
              v-for="(item, i) in secondNav"
              :key="i"
              :class="{ tabActivesecondNav: cur2 == item.id }"
              @click="changeSecondNav(item,i)"
          >
            {{ item.name }}
            <span :class="{tabActivesecondNavSpan: cur2 == item.id}">
                <span :class="{tabActivesecondNavSubSpan: cur2 == item.id}">

                </span>
            </span>
          </li>
        </ul>

      </div>
<!--      <div class="activity-switch-type">-->
<!--        <div class="activity-switch-type-title">活动类别：</div>-->
<!--        <div class="activity-switch-type-content">-->
<!--          <div-->
<!--            class="activity-switch-type-content-li"-->
<!--            :class="{-->
<!--              'activity-switch-type-content-li-active': current == index,-->
<!--            }"-->
<!--            v-for="(item, index) in categoryList"-->
<!--            :key="item.id"-->
<!--            @click="handleChangeValue(index, item.id)"-->
<!--          >-->
<!--            {{ item.name }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="activity-switch-type">
        <div class="activity-switch-type-title"></div>
        <div class="activity-switch-type-content">
          <div
            class="activity-switch-type-content-li"
            :class="{
              'activity-switch-type-content-li-active': current1 == index,'imgType':item.name.length > 4
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeStatus(index, item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <!-- 最新·最热 -->
      <div class="activity-content-hot-new">
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_left_icon.png"
            alt=""
          />
        </div>
        <div
          class="activity-content-hot-new-title"
          @click="handleChangeValue2('active_start_time')"
        >
          <div
            :style="{
              'font-weight': value2 == 'active_start_time' ? 'bold' : '',
            }"
          >
            最新
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{
              display: value2 == 'active_start_time' ? 'block' : 'none',
            }"
          ></div>
        </div>
        <div class="activity-content-new-mid-hot"></div>
        <div
          class="activity-content-hot-new-title activity-content-hot-new-title1"
          @click="handleChangeValue2('view_count')"
        >
          <div :style="{ 'font-weight': value2 == 'view_count' ? 'bold' : '' }">
            最热
          </div>
          <div
            class="activity-content-hot-new-title-bg"
            :style="{ display: value2 == 'view_count' ? 'block' : 'none' }"
          ></div>
        </div>
        <div class="activity-content-hot-new-icon">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/title_right_icon.png"
            alt=""
          />
        </div>
      </div>

      <div v-if="total > 0">
        <listItem
          listType="offline"
          :list="content"
          v-loading="listLoading"
          :currentName="currentName"
        ></listItem>
        <!-- 分页组件 -->
        <pagination
          :total="total"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getActivityList"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent
        title="暂无群文活动，请持续关注哦～"
        v-if="total == 0"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { getCateList, getActivityList } from "@/api/activity/activity";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
import {navChildList} from "@/api/header/header";
export default {
  data() {
    return {
      listLoading: true,
      total: 0,
      page: 1,
      limit: 9,
      aid: process.env.VUE_APP_AID,
      current: 0,
      current1: 0,
      id:this.$route.query.id,
      cur: 0,
      //活动状态，-1全部，1已结束，2报名中，3报名已结束活动未开始，4进行中，5报名未开始
      typeList: [
        { id: -1, name: "全部" },
        { id: 4, name: "报名中" },
        { id: 2, name: "进行中" },
        { id: 1, name: "已结束" },
      ],
      subTypeList: ["默认", "最新", "最热"],

      content: [],
      categoryList: [],
      option2: [
        { value: "active_start_time", label: "最新上线" },
        { value: "view_count", label: "人气最高" },
      ],
      value1: "全部",
      value2: "active_start_time",
      status: "",
      secondNav:[],
      cur2:0,
      navId:this.$route.query.navId
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
  },
  mounted() {
    window.scroll(0, 0);
    // alert(sessionStorage.getItem('secondSelectId'))
    this.getCategory().then((res) => {
      let data = res.data;
      data.unshift({ id: 0, name: "全部" });
      this.categoryList = data;
      this.getActivityList();
      this.getChildNav()
    });
  },
  methods: {
    changeSecondNav(item) {

      sessionStorage.setItem('secondSelectId',item.id)
      this.subTypeList = []
      this.curr = -1;
      if(item.url.indexOf("http")!=-1){
        window.open(item.url);
        }else{
      if(item.url.indexOf("navId")==-1){
        item.url +="&navId="+this.navId;
      }
      this.$router.push({
        path: item.url,
      });
        }
    },
    getChildNav() {
      var url = window.location.href
      url = url.split('?')[1];
      var name = this.getQueryString('name',url)
      let params = {
        aid: this.aid,
        pid: this.navId,
        app_type: 1,
      };  navChildList(params).then((res) => {
        this.secondNav = res.data
        if (res.code == 0) {
          this.secondNav = res.data
          var data = res.data;
          this.secondNav = data;
          var status = false
          data.forEach((item,index) => {
            if (item.name == this.$route.query.name) {
              this.cur2 = item.id;
              status = true
              this.currentName = item.name
            }else if(!status && item.id == sessionStorage.getItem('secondSelectId')) {
              this.cur2 = item.id;
              this.currentName = item.name
            }
          });
        }
      });
    },
    getCategory() {
      return getCateList({ aid: this.aid,id:42 });
    },
    getQueryString(key,url){
        var reg = new RegExp("(^|&)"+key+"=([^&]*)(&|$)");
        var result = url.substr(1).match(reg);
        return result?decodeURIComponent(result[2]):null;
    },
    handleChangeStatus(i, status) {
      this.page = 1;
      this.current1 = i;
      this.status = status;
      this.getActivityList(status);
    },
    handleChangeValue(i, value1) {
      this.page = 1;
      this.current = i;
      this.value1 = value1;
      this.getActivityList(this.status);
    },

    handleChangeValue2(value2) {
      this.page = 1;
      this.value2 = value2;
      this.getActivityList(this.status);
    },

    getActivityList() {
      this.typeList.forEach((item) => {
        item.id;
      });
      let params = {
        aid: this.aid,
        page: this.page,
        page_size: this.limit,
        cat1:this.id,
        status:this.status,
      };
      if (this.value2 != "") {
        params.sort_field = this.value2;
      }
      console.info(this.value2);
      if (parseInt(this.value1) > 0) {
        params.cat1 = parseInt(this.value1);
      }
      getActivityList(params).then((res) => {
        // this.listLoading = true;
        console.log(res)
        if (res.code == 0) {
          this.content = res.data.datalist;
          this.total = res.data.total;
        }
      });
      this.listLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/activity.scss";
.tab-secondNav-choose-item{
  font-size: 0.17rem;
  border-bottom: 1px solid #D5D5D5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item{
  display: flex;
}
.tab-secondNav-choose-item li{
  margin-left: 10px;
}
.tab-secondNav-choose-item li{
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child){
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav{
  color: #761F1E;
  position: relative;
}

.tabActivesecondNavSpan{
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan{
  width: 80%;
  background: #991C10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch{
  margin-top: 0.33rem;
}
.news-container{
  padding-top: 0.38rem;
}
.tabActivesecondNav{
  overflow: visible;
}
</style>
