var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-container"},[_c('div',{staticClass:"activity-content"},[(_vm.secondNav.length>0)?_c('div',{staticClass:"tab-secondNav"},[_c('ul',{staticClass:"tab-secondNav-choose-item"},_vm._l((_vm.secondNav),function(item,i){return _c('li',{key:i,class:{ tabActivesecondNav: _vm.cur2 == item.id },on:{"click":function($event){return _vm.changeSecondNav(item,i)}}},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{class:{tabActivesecondNavSpan: _vm.cur2 == item.id}},[_c('span',{class:{tabActivesecondNavSubSpan: _vm.cur2 == item.id}})])])}),0)]):_vm._e(),_c('div',{staticClass:"activity-switch-type"},[_c('div',{staticClass:"activity-switch-type-title"}),_c('div',{staticClass:"activity-switch-type-content"},_vm._l((_vm.typeList),function(item,index){return _c('div',{key:item.id,staticClass:"activity-switch-type-content-li",class:{
              'activity-switch-type-content-li-active': _vm.current1 == index,'imgType':item.name.length > 4
            },on:{"click":function($event){return _vm.handleChangeStatus(index, item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"activity-content-hot-new"},[_vm._m(0),_c('div',{staticClass:"activity-content-hot-new-title",on:{"click":function($event){return _vm.handleChangeValue2('active_start_time')}}},[_c('div',{style:({
              'font-weight': _vm.value2 == 'active_start_time' ? 'bold' : '',
            })},[_vm._v(" 最新 ")]),_c('div',{staticClass:"activity-content-hot-new-title-bg",style:({
              display: _vm.value2 == 'active_start_time' ? 'block' : 'none',
            })})]),_c('div',{staticClass:"activity-content-new-mid-hot"}),_c('div',{staticClass:"activity-content-hot-new-title activity-content-hot-new-title1",on:{"click":function($event){return _vm.handleChangeValue2('view_count')}}},[_c('div',{style:({ 'font-weight': _vm.value2 == 'view_count' ? 'bold' : '' })},[_vm._v(" 最热 ")]),_c('div',{staticClass:"activity-content-hot-new-title-bg",style:({ display: _vm.value2 == 'view_count' ? 'block' : 'none' })})]),_vm._m(1)]),(_vm.total > 0)?_c('div',[_c('listItem',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"listType":"offline","list":_vm.content,"currentName":_vm.currentName}}),_c('pagination',{attrs:{"total":_vm.total,"page":_vm.page,"limit":_vm.limit},on:{"update:page":function($event){_vm.page=$event},"update:limit":function($event){_vm.limit=$event},"pagination":_vm.getActivityList}})],1):_vm._e(),(_vm.total == 0)?_c('Nocontent',{attrs:{"title":"暂无群文活动，请持续关注哦～"}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_left_icon.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-content-hot-new-icon"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://image.bookgo.com.cn/webculture/jm/title_right_icon.png","alt":""}})])
}]

export { render, staticRenderFns }